
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import AtomInputStepper from '@/components/atoms/AtomInputStepper.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomTextInput from '@/components/atoms/AtomInput/AtomTextInput.vue';
import AtomActionText from '@/components/atoms/AtomActionText.vue';
import AtomSelectButton from '@/components/atoms/AtomSelectButton.vue';
import AtomCard from '@/components/atoms/AtomCard.vue';
import MoleculeCarPicker from '@/components/molecules/car/MoleculeCarPicker.vue';
import OrganismAddCar from '@/components/organisms/car/OrganismAddCar.vue';
import CarTypeEnum from '@/enums/car/CarTypeEnum';
import CarSeatsEnum, { PoolCarSeatsEnum } from '@/enums/car/CarSeatsEnum';
import FeatureEnum from '@/enums/settings/FeatureEnum';
import RolesEnum from '@/enums/rides/RolesEnum';
import SwitchEnum from '@/enums/switch/SwitchEnum';
import { StepperSteps } from '@/enums/stepper/StepperEnum';
import { SelectedCar, CarRentalData } from '@/store/modules/ride-intent/state';
import { Car } from '@/store/modules/car/state';
import { hasFeature } from '@/services/feature/FeatureService';
import AtomSixtBanner from '@/components/atoms/AtomSixtBanner.vue';

export default Vue.extend({
  name: 'OrganismCarSelection',
  components: {
    AtomSelectButton,
    AtomInputStepper,
    AtomText,
    AtomTextInput,
    AtomCard,
    AtomActionText,
    MoleculeCarPicker,
    OrganismAddCar,
    AtomSixtBanner,
  },
  data: () => ({
    selectedId: null as string | null,
    freeSeats: CarSeatsEnum.DEFAULT_FREE_SEATS,
    activeRadioButton: CarTypeEnum.OWN_CAR,
    CarRentalData,
    CarTypeEnum,
    CarSeatsEnum,
    PoolCarSeatsEnum,
    SwitchEnum,
    FeatureEnum,
    RolesEnum,
  }),
  mounted() {
    this.addUserCars();
    this.init();
  },
  computed: {
    ...mapGetters('rideIntent', ['getSelectedRole', 'getReturnTripStatus', 'getSelectedCar', 'isEditMode', 'isPoolCarRequest']),
    ...mapGetters('car', {
      getAllCars: 'getAllCars',
      getCarById: 'getCarById',
    }),
    ...mapGetters('user', ['getCidaasUserData', 'getAccessToken', 'features', 'b2cFree']),
    isPoolCarActive(): boolean {
      const { getReturnTripStatus, getSelectedRole } = this;
      return (getReturnTripStatus === SwitchEnum.ON && getSelectedRole === RolesEnum.DRIVER_AND_PASSENGER);
    },
    isPoolCarActiveInEditMode(): boolean {
      const { getReturnTripStatus, isEditMode } = this;
      return (isEditMode && getReturnTripStatus === SwitchEnum.OFF);
    },
    defaultOwnCar(): Car|null {
      if (!this.getAllCars.length) return null;
      return this.getAllCars.find((c: Car) => c.isDefaultCar) || this.getAllCars[0] as Car || null;
    },
    showSixtBanner(): boolean {
      return this.b2cFree || hasFeature(this.features, FeatureEnum.SIXT_BANNER);
    },
  },
  methods: {
    ...mapActions('rideIntent', [
      'setSelectedCar',
      'setSelectedRole',
      'toggleOutwardTripSteps',
      'jumpToStep',
      'addCarRentalData',
      'addCarRentalSeats',
      'validateCarSelection',
    ]),
    ...mapActions('car', ['addCar', 'removeAllCars', 'setAvailableSeats', 'fetchAllCars']),
    init() {
      const { getSelectedCar, defaultOwnCar, setSelectedCar, validateCarSelection } = this;
      const carType = getSelectedCar ? getSelectedCar.type : CarTypeEnum.OWN_CAR;
      this.activeRadioButton = carType;
      if (carType === CarTypeEnum.OWN_CAR && !getSelectedCar) setSelectedCar(defaultOwnCar);

      validateCarSelection();
    },
    isCarSelected(car: Car): boolean {
      if (!this.getSelectedCar) return car.isDefaultCar;
      return car.id === this.getSelectedCar.id;
    },
    getAvSeats(car: Car) {
      if (!this.getSelectedCar) return CarSeatsEnum.DEFAULT_FREE_SEATS;
      return !this.isCarSelected(car) ? car.freeSeats : this.getSelectedCar.freeSeats;
    },
    addUserCars() {
      if (this.getAllCars.length === 0) {
        this.fetchAllCars(this.getCidaasUserData.sub);
      }
    },
    updateAvSeats(id: string, value: number) {
      this.setSelectedCar({ ...this.getSelectedCar, freeSeats: value });
    },
    addNewCar(id: string) {
      const newCar = this.getCarById(id);
      this.changeActiveCar({
        id: newCar.id,
        freeSeats: newCar.freeSeats,
        type: CarTypeEnum.OWN_CAR,
      });
    },
    changeActiveCar(selectedCar: SelectedCar) {
      this.selectedId = selectedCar.id;
      this.activeRadioButton = CarTypeEnum.OWN_CAR;
      this.setSelectedCar(selectedCar);
    },
    setAllCarsInactive() {
      this.selectedId = null;
    },
    switchRadioButtons(id: CarTypeEnum) {
      // console.log(this.getAllCars.length);
      this.activeRadioButton = id;
      // Disable free seats selection when other car type active
      if (id === CarTypeEnum.OWN_CAR) {
        this.setSelectedCar(this.defaultOwnCar);
      } else if (id === CarTypeEnum.POOL_CAR) {
        this.setSelectedCar({
          type: CarTypeEnum.POOL_CAR,
          freeSeats: this.freeSeats,
          id: null,
        });
      } else if (id === CarTypeEnum.RENTAL_CAR) {
        this.setSelectedCar({
          type: CarTypeEnum.RENTAL_CAR,
          freeSeats: this.freeSeats,
          id: null,
        });
      }
    },
    async changeUserRole() {
      await this.setSelectedRole(RolesEnum.DRIVER_AND_PASSENGER);
      this.setSelectedCar({
        type: CarTypeEnum.POOL_CAR,
        id: null,
      });
    },
    ownCarIsSelected() {
      this.validateCarSelection();
      return this.getAllCars.some((car) => car.active);
    },
    addReturnTripDetails() {
      this.toggleOutwardTripSteps();
      this.jumpToStep(StepperSteps.INBOUND_TRIP);
    },
    hasFeature,
  },
});
