
import Vue from 'vue';
import { BButton } from 'bootstrap-vue';
import AtomBadge, { BadgeVariant } from '@/components/atoms/AtomBadge.vue';
import AtomInputStepper from '@/components/atoms/AtomInputStepper.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomCircularIcon, {
  CircularIconVariant,
  CircularIconSize,
} from '@/components/atoms/AtomCircularIcon.vue';
import { SelectedCar } from '@/store/modules/ride-intent/state';
import CarTypeEnum from '@/enums/car/CarTypeEnum';
import CarSeatsEnum from '@/enums/car/CarSeatsEnum';

export default Vue.extend({
  name: 'MoleculeCarPicker',
  components: {
    AtomBadge,
    AtomSvgIcon,
    AtomCircularIcon,
    AtomInputStepper,
    AtomText,
    BButton,
  },
  props: {
    id: {
      type: String,
      required: false,
    },
    availableSeats: {
      type: Number,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    licensePlate: {
      type: String,
      required: false,
    },
    imageURL: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
    addCar: {
      type: Boolean,
      required: false,
      default: false,
    },
    poolCar: {
      type: Boolean,
      required: false,
      default: false,
    },
    setActive: {
      type: Function,
      required: false,
    },
  },
  data: () => ({
    CarSeatsEnum,
    CircularIconVariant,
    CircularIconSize,
    BadgeVariant,
    seats: CarSeatsEnum.DEFAULT_FREE_SEATS,
  }),
  methods: {
    changeAvailableSeats(modifier: number) {
      this.seats = modifier;
      this.$emit('changeAvailableSeats', this.id, this.seats);
    },
    buttonEventHandler() {
      if (!this.poolCar && this.setActive) {
        const currentCar: SelectedCar = {
          type: CarTypeEnum.OWN_CAR,
          id: this.id,
          freeSeats: this.availableSeats,
        };
        this.setActive(currentCar);
      }
    },
  },
});
