enum CarSeatsEnum {
  DEFAULT_FREE_SEATS = 3,
  MIN_FREE_SEATS = 1,
  MAX_FREE_SEATS = 20,
}

export enum PoolCarSeatsEnum {
  DEFAULT_FREE_SEATS = 3,
  MIN_FREE_SEATS = 2,
  MAX_FREE_SEATS = 21,
}

export default CarSeatsEnum;
